import axiosInstance, {
  fetcher,
  noRevalidateOnlyStaleOptions,
  revalidateOnlyStaleOptions,
} from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { getURL } from 'src/utils/url';
import { cloneAndCleanIsActive, isEmpty } from 'src/utils/type_check';
import { deleteToken, getToken } from 'src/utils/token';
import _ from 'lodash';

var currentURL = '';

export const defaultCustomerParam = {
  pageSize: 5,
  pageNumber: 1,
  roleType: 'customer',
  isActive: true,
};

export function useGetMe() {
  var URL = ENDPOINTS.user.get_me;
  var token = getToken();
  if (token === null) URL = null;
  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    noRevalidateOnlyStaleOptions,
  );
  const memoizedValue = useMemo(
    () => ({
      user: data?.result || {},
      isAdmin: data?.result?.roleType === 'admin',
      customerId:
        data?.result?.roleType === 'customer' || data?.result?.roleType === 'staff'
          ? data?.result?.userId
          : '',
      userId: data?.result?.userId ?? '',
      role: data?.result?.roleType ?? '',
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  if (!isLoading && isEmpty(memoizedValue.user) && token !== null) {
    deleteToken();
  }
  return memoizedValue;
}

export function useGetUsersWithParams(params) {
  var clone = cloneAndCleanIsActive(params, params.userId);
  const URL = getURL(ENDPOINTS.user.get_list, clone);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result?.list || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      totalCount: data?.result.count.totalCount,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export function useGetActiveUser(params) {
  const URL = getURL(ENDPOINTS.user.get_main, params);
  currentURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchCount: {
        admin: data?.result?.info?.adminInfo?.count?.totalCount ?? 0,
        location: data?.result?.info?.locationInfo?.count?.totalCount ?? 0,
        customer: data?.result?.info?.customerInfo?.count?.totalCount ?? 0,
      },
      customersList: data?.result?.info?.customerInfo?.list ?? [],
      locationsList: data?.result?.info?.locationInfo?.list ?? [],
      adminsList: data?.result?.info?.adminInfo?.list ?? [],
      searchLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetActiveUserForLocation(locationId, params) {
  const URL = getURL(ENDPOINTS.user.get_location(locationId), params);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchCount: {
        locationManager: data?.result?.info?.locationManagerInfo?.count?.totalCount ?? 0,
        worker: data?.result?.info?.workerInfo?.count?.totalCount ?? 0,
      },
      locationManagerList: data?.result?.info?.locationManagerInfo?.list ?? [],
      workerList: data?.result?.info?.workerInfo?.list ?? [],
      searchLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetActiveUserForCustomer(customerId, params) {
  const URL = getURL(ENDPOINTS.user.get_customer(customerId), params);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchCount: {
        staff: data?.result?.count?.totalCount ?? 0,
      },
      staffList: data?.result?.list ?? [],
      searchLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export async function createUser(userData) {
  var ret = await axiosInstance.post(ENDPOINTS.user.post, userData);
  await mutate(currentURL);
  return ret.data;
}

export async function editUser(userData, userId) {
  const endpoint = ENDPOINTS.user.put(userId);
  var clone = _.cloneDeep(userData);
  delete clone.loginPassword;
  var ret = await axiosInstance.put(endpoint, clone);
  await mutate(currentURL);
  await mutate(ENDPOINTS.user.get_me);
  return ret.data;
}

export async function deleteUser(userId) {
  const endpoint = ENDPOINTS.user.put(userId);
  var ret = await axiosInstance.delete(endpoint);
  await mutate(currentURL);
  return ret.data;
}
